import React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import Stack from '@mui/material/Stack';

function valuetext(value) {
  return `${value}°C`;
}


 function Daysliders(props) {
  return (
    <Box sx={{ width: '100%' }}>

    <div style={{fontSize:'.8em'}}>Total Days</div>
    <Slider        
        value={props.viewmaxdays}
        color="secondary"
        valueLabelDisplay="off"
        max={props.viewmaxdays}
        min={0}
      />


<div style={{fontSize:'.8em'}}>Total Days</div>
      <Slider        
        value={props.interestDays}
        defaultValue={props.interestDays}
        color="primary"
        valueLabelDisplay="off"
      />
<div style={{fontSize:'.8em'}}>Total Days</div>
      <Slider        
        value={props.myval}
        defaultValue={0}
        color="secondary"
      />

    </Box>
  );
}

export default Daysliders;

// 870
// 0427
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from '../pages/Home';
import Calculator from '../pages/Calculator';
import StakingCalculator from '../pages/StakingCalculator';
import Layout from "../pages/Layout";



function App() {
  return (
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="stakingcalculator" element={<StakingCalculator />} />
                <Route path="calculator" element={<Calculator />} />
{/*                <Route path="contact" element={<Contact />} />
                <Route path="*" element={<NoPage />} />*/}
              </Route>
            </Routes>
          </BrowserRouter>

  );
}

export default App;



import React, {useState, useEffect} from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';


import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';


import "./Calculator.css";
import Daysliders from '../components/Daysliders';
import StatusBox from '../components/StatusBox';
import DayController from '../components/DayController';







const Calculator = () => {
  
  // Set Values
  let [totalSupply, setTotalSupply] = useState(0);



  // User Input Values 

  let [tfstartdayerror, settfstartdayerror] = useState();
  let [startDay, setStartDay] = useState(0);
  let [stakeDays, setStakeDays] = useState(0);
  let [principle, setPrinciple] = useState(0);
  let [scrapeDay, setScrapeDay] = useState(0);

  //Calculated Values
  let [endDay, setEndDay] = useState(0);
  let [possibleInterestDays, setPossibleInterestDays] = useState(0);
  let [possibleInterestAmount, setPossibleInterestAmount] = useState(0);
  let [currentDay, setCurrentDay] = useState(0);
  let [interestDays, setInterestDays] = useState(0);
  let [interestDayStart, setInterestDayStart] = useState(0);
  let [previousScrapeDays, setPreviousScrapeDays] = useState(0);
  let [inStake, setInStake] = useState(0);
  
//Page Variables
  let [dayColor, setDayColr] = useState("secondary");
  let [statusColor, setStatusColor] = useState("primary");
  
  let [stakelist, setStakeList] = useState([]);
  let [staketext, setStateText] = useState("");


  // for viewing things
  let [viewMaxDays, setViewMaxDays] = useState(100);
  let [dayResolution, setDayResolution] = useState(1);

  useEffect(() => {

    // set Current Day
    setTotalSupply(5000);
    setStartDay(20);    
    setCurrentDay(14);   
    setPrinciple(1000); 
    setStakeDays(35);   
    setInStake(0);  //pseudo boolean


  }, []);

  const clickAddStake = () => {

      let stake = {principle: principle, startDay: currentDay, stakeDays: stakeDays};

      setStakeList([...stakelist, stake]);

  }

  const logme = (myval) => {

      console.log(stakelist);

  }

  const renderStakelist = (stakelist) => {

    return (
        <div>List of stakes</div>
      );
  }

  const currentDayChange = (newDay) => {
    
    setCurrentDay(newDay);
    updateMe();


  }

  const updateMe = ()=>{


    // Calculate End Day
    setEndDay(stakeDays + startDay);

    // CalculateTotalViewDays
    // let vmd = stakeDays + startDay;
    // if((vmd) > currentDay ){
    //   setViewMaxDays(stakeDays + startDay);
    // }  else {
    //   setViewMaxDays(currentDay);
    // }

    // Calculate Interest Days
    let iDays = (currentDay -  (scrapeDay - startDay)) ;
    
    if (iDays <= 0 ){ iDays = 0; }

    setInterestDays(iDays);



    // Calculate Possible Interest Days
    //total days in stake minus scrapedaycount

    let scrapeDayCount = scrapeDay-startDay;
    scrapeDayCount = (scrapeDayCount <=0)? 0 : scrapeDayCount;
    setPossibleInterestDays(scrapeDayCount);



    // Calculate Interest Start Day
    let idaysstart = currentDay - (scrapeDay - startDay)
    setInterestDayStart(idaysstart);



    let prevScrapeDays = scrapeDay - startDay;
    if(prevScrapeDays <=0){
      prevScrapeDays = 0;
    }
    setPreviousScrapeDays(prevScrapeDays);

    // Set statuses for the period it is in
    if(currentDay > startDay && currentDay < endDay && currentDay > scrapeDay){
      //this is in IN STAKE
      setDayColr("success");
      setStatusColor("astonmartin");
    } else if(currentDay > startDay && currentDay < scrapeDay ) {
      setDayColr("secondary");
      setStatusColor('ferrari');
    } else {
      setDayColr("primary");
      setStatusColor("primary");
    }

    runCalculations(iDays);
    //runCalculations();



  }



    const runCalculations = (totaldays = 0) => { 
      
      console.log("Starting Calculations");

      let idxs = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22, 23];

      // From Solidity Contract
      let tableOfInterest = [ 602327173169062, 626992495575658, 682465042095688, 761985998481203, 862695877467196, 982810652163463, 1121060227005890, 1276472401104000, 1448267589178370, 1635800001127570, 1838521643517700, 2055958793232460, 2287695837361680, 2533363729811420, 2792631480698150, 3065199715661290, 3350795693481670, 3649169379008750, 3960090297482470, 4283344979037570, 4618734856781090, 4966074518839090, 0 ];
      
      // Decimals to be used because the above array is determined by 18 decimals
      let decimals = 1000000000000000000;

      let final = []; // used for console log testing

      let yeardivisor = 365.25;
  
      let totaldays2 = 0;
      let  myinterest = 0;

      let totalinterest = 0;




      // setPossibleInterestDays(totaldays);

      let txt2 = idxs.map((idxs, index) => {
 
              {/*<div key={`${idxs}`}>{idxs}</div>*/}

              if(totaldays < 0) {
                totaldays = 0;
                totaldays2 = 0;
              } else if(totaldays < yeardivisor){
                totaldays2 = totaldays;
              } else {
                totaldays2 = yeardivisor;
              }

              totaldays = totaldays - yeardivisor;

              myinterest = ((totaldays2 * tableOfInterest[index]) * principle) / decimals;
              // add to the total Interest
              totalinterest = totalinterest + myinterest;

              final.push({yr: idxs, td: totaldays2, interest: myinterest, irate: tableOfInterest[index]  });

        });

      console.log(final);
      console.log(totalinterest);
      setPossibleInterestAmount(totalinterest);

    }

    const _setCurrentDay = (thisval)=>{
      thisval = parseInt(thisval);
      if(isNaN(thisval)){
        thisval = 0;
      }
      setCurrentDay(thisval);
      return 
    }

    const _setPrinciple = (thisval)  =>{
      thisval = parseInt(thisval);

      if(isNaN(thisval)){
        thisval = 0;
      }
      setPrinciple(thisval);
      return 
    }
    const _setStartDay = (thisval)  =>{
      thisval = parseInt(thisval);
      // reset error
      settfstartdayerror("");

      if(isNaN(thisval)){
        thisval = 0;
      }
    
      // if(thisval > currentDay){
      //   settfstartdayerror("error");
      //   thisval = currentDay;
      // }
      
      setStartDay(thisval);
      return 
    }

    const _setStakeDays = (thisval)  =>{
      thisval = parseInt(thisval);

      if(isNaN(thisval)){
        thisval = 0;
      }
      setStakeDays(thisval); 
      return 
    }
    const _setScrapeDay = (thisval)  =>{
      thisval = parseInt(thisval);

      if(isNaN(thisval)){
        thisval = 0;
      }
      setScrapeDay(thisval); 
      return 
    }



  return (

      <Container>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                
                <TextField id="tf_currendDay" label="Current Day" variant="filled" size="small" value={currentDay} onChange={ (e)=>{ _setCurrentDay(e.target.value) } } />
                <TextField id="tf_principle" label="Principle" variant="filled"  size="small"   value={principle} onChange={ (e)=>{  _setPrinciple(e.target.value)   } } />
                <TextField id="tf_stakeDays" label="Start Day" variant="filled" size="small"  value={startDay} onChange={ (e)=>{    _setStartDay(e.target.value)   } } />
                <TextField id="tf_stakeDays" label="Stake Days" variant="filled" size="small"    value={stakeDays} onChange={ (e)=>{  _setStakeDays(e.target.value)   } } />
                <TextField id="tf_stakeDays" label="Scrape Day" variant="filled" size="small"   value={scrapeDay} onChange={ (e)=>{  _setScrapeDay(e.target.value)   } } />

                <Button onClick={ (e)=>{updateMe()} }>Run</Button>
                {/*<Button onClick={ (e)=>{} } >runCalculation</Button>*/}
                <Button onClick={logme()}>stakelist</Button>
            
            </Grid>
            </Grid>

          </Grid>
          <Grid item xs={4}>
            <Box >
                <div className="ptxt"><div className="ptxtlabel">TotalSupply:</div> {totalSupply} </div>
                <div className="ptxt"><div className="ptxtlabel">CurrentDay:</div> {currentDay} </div>
                <div className="ptxt"><div className="ptxtlabel">StartDay:</div> {startDay} </div>
                <div className="ptxt"><div className="ptxtlabel">EndDay:</div> {endDay} </div>
                <div className="ptxt"><div className="ptxtlabel">ScrapeDay:</div> {scrapeDay} </div>
                <div className="ptxt"><div className="ptxtlabel">InterestDays:</div> {interestDays} </div>
                <div className="ptxt"><div className="ptxtlabel">PossIntDays:</div> {possibleInterestDays} </div>
                                

                <div className="ptxt"><div className="ptxtlabel">PrevScrapeDays:</div> {previousScrapeDays} </div>
                <div className="ptxt"><div className="ptxtlabel">viewMaxDays:</div> {viewMaxDays} </div>
                   

                <div className="ptxt"><div className="ptxtlabel">StakeDays:</div> {stakeDays} </div>
                <div className="ptxt"><div className="ptxtlabel">P Int Amt:</div> {possibleInterestAmount} </div>
                <div className="ptxt"><div className="ptxtlabel">Interest Days:</div> {interestDays} </div>
            </Box>
          </Grid>

          <Grid item xs={12} className={dayColor}>
            {/*<Daysliders 
                myval={[12,3,37,viewMaxDays]} 
                interestDays={[0,15, viewMaxDays]} 
                viewmaxdays={viewMaxDays} />  
            */}

            <StatusBox clr= {statusColor} totaldays={viewMaxDays}/>


          </Grid>


{/* -------------------------------------------*/}
          <Grid item xs={4}>
            {/*{renderStakelist(stakelist)}*/}  
            View Resolution
          </Grid>

          <Grid item xs={8}>
             <Slider        
                onChange={(e)=>{setViewMaxDays(e.target.value)}}
                value={viewMaxDays}
                defaultValue={viewMaxDays}
                color="primary"
                valueLabelDisplay="on"
                max={10000}
                min={100}
               />
          </Grid>
{/* -------------------------------------------*/}

{/* -------------------------------------------*/}
          <Grid item xs={4}>
            Current Day
            <Box onClick={()=>{setCurrentDay(currentDay + 1) ; updateMe()}} sx={{cursor: 'pointer',display: 'inline-block',height: 20, width: 20, backgroundColor: 'blue', textAlign: 'center', color: 'white'}}>+</Box>
          <Box onClick={()=>{setCurrentDay(currentDay - 1) ; updateMe()}} sx={{display: 'inline-block',height: 20, width: 20, backgroundColor: 'red', textAlign: 'center', color: 'white'}}>-</Box>
          </Grid>

          <Grid item xs={8}>
             <Slider        
                onChange={(e)=>{currentDayChange(e.target.value)}}
                value={currentDay}
                defaultValue={currentDay}
                color={dayColor}
                valueLabelDisplay="on"
                max={viewMaxDays}
                min={0}
               />
          </Grid>
{/* -------------------------------------------*/}



        </Grid>


      </Container>

    );
};

export default Calculator;
import * as React from 'react';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import CakeIcon from '@mui/icons-material/Cake';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export default function PossibleArrayViewer(props) {
  // this comes in as an array of objects

    const listItems = props.possiblearray.map((thisyear, index)=>{
      return (
          <Box key={`pia_${index}` } sx={{fontSize: '9px'}} >
            TD: {thisyear['td']} | 
          Interest:  {thisyear['interest'] / 1000000000000000000}

          </Box>
        )

    });
    
    // let idxs = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];

    //   const listItems = props.ya.map((idx, index)=>{
    //     let clr = 'primary';
    //     if(idx <= 0){
    //       clr = 'default';
    //     }else if(idx > 0 && idx < 365.25){
    //       clr = 'secondary';
    //     } else {
    //       clr = 'primary';
    //     }
    //     return(
    //     // <Badge key={index} color="secondary" badgeContent={idx} max={366} showZero>
    //     //     <CakeIcon />
    //     // </Badge>
        
    //       // <Chip label={idx} color={clr} variant="outlined"/>  
    //     <Box key={index} item sx={{fontSize:'9px'}}>
    //        Year: {index} : {idx}
    //       </Box>

    //     )
    //   });
  
  return (
    <Stack spacing={0} direction="column" sx={{ color: 'action.active' }}>
       {listItems}
     
    </Stack>


  );
}
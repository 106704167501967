import { Outlet, Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';


import CssBaseline from '@mui/material/CssBaseline';
const Layout = () => {
  return (
    <>
      
      <CssBaseline />

      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
{/*          <li>
            <Link to="/calculator">Calculator</Link>
          </li>*/}
          <li>
            <Link to="/stakingcalculator">Staking Calculator</Link>
          </li>
{/*          <li>
            <Link to="/contact">Contact</Link>
          </li>*/}
        </ul>
        </nav>
              <Container maxWidth="sm">
        <Box sx={{ bgcolor: '#f1f1f1', height: '100vh', maxHeight: '70vh' }}>

            <Outlet />

        </Box>
      </Container>

      

    </>
  )
};

export default Layout;
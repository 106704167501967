import React, {useState} from 'react';
import { Box, ThemeProvider } from '@mui/system';

export default function StatusBox(props) {

  let [mycolor, setMycolor] = useState('primary');
  let [statusStakeStart, setSliderStakeStart] = useState(0);



  const makeColors = () => {


  }

  return (
    <ThemeProvider
      theme={{
        palette: {
          primary: {
            base: '#bbbbbb',
            main: '#007FFF',
            dark: '#0059B2',
          },
          marbled: {
            base: '#bbbbbb',
            main: '#ff7FFF',
            dark: '#ff59B2',
          },
          basic: {
            base: '#bbbbbb',
            main: '#cccccc',
            dark: '#888888',
          },
          mercedes: { base: '#bbbbbb',main: '#00D2BE',    dark: '#00927E', one: '#00ffff' },
          redbull: { main: '#0600EF',     dark: '#0300AF',},
          ferrari: { main: '#DC0000',     dark: '#96A000', one: '#ffff00'},
          alpine: { main: '#0090FF',      dark: '#0050cc',},
          haas: { main: '#FFFFFF',      dark: '#cccccc',},
          astonmartin: { base: '#aaaaaa', main: '#006F62',   dark: '#002c22', one: '#ff00ff'},
          alphatauri: { main: '#2B4562',    dark: '#2B2522',},
          mclaren: { main: '#FF8700',     dark: '#003700',},
          alpharomeo: { main: '#900000',    dark: '#400000',},
          williams: { main: '#005AFF',    dark: '#005AAA',},
          racingpoint: { main: '#ffD2BE',   dark: '#AAD2BE',},
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: 30,
          bgcolor: `${props.clr}.base`,
          '&:hover': {
            backgroundColor: `${props.clr}.dark`,
            opacity: [0.9, 0.8, 0.7],
          },
        }}
      >

      
      </Box>

        

    </ThemeProvider>
  );
}


          
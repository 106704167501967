import React, {useState, useEffect} from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

// icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import "./Calculator.css";
import Daysliders from '../components/Daysliders';
import StatusBox from '../components/StatusBox';
import DayController from '../components/DayController';
import YearArrayViewer from '../components/YearArrayViewer';

import PossibleArrayViewer from '../components/PossibleArrayViewer';






const StakingCalculator = () => {
  
  // Set Values
  let [totalSupply, setTotalSupply] = useState(0);



  // User Input Values 

  let [tfstartdayerror, settfstartdayerror] = useState();
  let [startDay, setStartDay] = useState(0);
  let [stakeDays, setStakeDays] = useState(0);
  let [principle, setPrinciple] = useState(0);
  let [scrapeDay, setScrapeDay] = useState(0);

  //Calculated Values
  let [endDay, setEndDay] = useState(0);
  let [possibleInterestDays, setPossibleInterestDays] = useState(0);
  let [possibleInterestAmount, setPossibleInterestAmount] = useState(0.0);
  let [currentDay, setCurrentDay] = useState(0);
  let [interestDays, setInterestDays] = useState(0);
  let [interestDayStart, setInterestDayStart] = useState(0);
  let [currentInterestAmount, setCurrentInterestAmount] = useState(0);

  let [scrapedInterestAmount, setScrapedInterestAmount] = useState(0);

  let [finalInterestAmount, setFinalInterestAmount] = useState(0);



  let [previousScrapeDays, setPreviousScrapeDays] = useState(0);
  let [stakeStarted, setStakeStarted] = useState(false);
  let [inStake, setInStake] = useState(0);
  let [yearArray, setYearArray] = useState([]);
  let [yearArray2, setYearArray2] = useState([]);

  let [possiblearray, setPossibleArray] = useState([]);
  let [finalarray, setFinalArray] = useState([]);

//Page Variables
  let [dayColor, setDayColr] = useState("secondary");
  let [statusColor, setStatusColor] = useState("primary");
  const [dayPCTCheck, setDayPCTCheck] = React.useState(false);
  
  let [endStakeButtonDisabled, setEndStakeButtonDisabled] = useState("disabled");
  let [dayUpDown, setDayUpDown] = useState(1);

  let [stakelist, setStakeList] = useState([]);
  let [staketext, setStateText] = useState("");


  // for viewing things
  let [viewMaxDays, setViewMaxDays] = useState(100);
  let [dayResolution, setDayResolution] = useState(1);

  useEffect(() => {

    // set Current Day
    setTotalSupply(5000);
    setStartDay(20);    
    setCurrentDay(14);   
    setPrinciple(1000); 
    setStakeDays(35);   
    setInStake(0);  //pseudo boolean


  }, []);

  const clickAddStake = () => {

      let stake = {principle: principle, 
        startDay: currentDay, 
        stakeDays: stakeDays};

      setStakeList([...stakelist, stake]);

  }

  const logme = (myval) => {

      console.log(stakelist);

  }

  const renderStakelist = (stakelist) => {

    return (
        <div>List of stakes</div>
      );
  }

  const currentDayChange = (newDay) => {
    
    setCurrentDay(newDay);
    updateMe(newDay);


  }

  const updateMe = (z_newDay=0)=>{
    //console.log(currentDay, z_newDay);

    // because of state variables not being immeidate,
    // create vars based on need, then assign them to 
    // global vars for viewing on screen aftewards
    // some variables, the ones contingent on state values
    // are fine but those necessary for this to calculate
    // need to be immediate.


    let z_endDay = 0;
    let z_currentDay = z_newDay;


    // Calculate End Day
    z_endDay = stakeDays + startDay;

    setEndDay(z_endDay);

    // Calculate Interest Days
    let z_interestDays = 0;

    
    // Scrape Day Requirements
    if(scrapeDay == 0 || scrapeDay < startDay ){
      setScrapeDay(0);
      z_interestDays = z_currentDay - startDay;
    } else if(scrapeDay > z_endDay){
      setScrapeDay(z_endDay);
      z_interestDays = 0;
    } else {
      // 
      z_interestDays = ((z_currentDay - startDay) -  (scrapeDay - startDay)) ;
    }

    // Extra check  where interest days can't be less than zero    
    if (z_interestDays <= 0 ){ z_interestDays = 0; }

    // Extra check against possible Interest Days Being Greater than total Stake Days
    if(z_interestDays > possibleInterestDays){
      z_interestDays = possibleInterestDays;
    }

    setInterestDays(z_interestDays);



    // Calculate Interest Start Day
    let idaysstart = currentDay - (scrapeDay - startDay)
    setInterestDayStart(idaysstart);



    let prevScrapeDays = scrapeDay - startDay;
    if(prevScrapeDays <=0){
      prevScrapeDays = 0;
    }
    setPreviousScrapeDays(prevScrapeDays);

    // Set statuses for the period it is in
    if(currentDay > startDay && currentDay < endDay && currentDay > scrapeDay){
      //this is in IN STAKE
      setDayColr("success");
      setStatusColor("astonmartin");
      setInStake(true);


    } else if(currentDay > startDay && currentDay < scrapeDay ) {
      setDayColr("secondary");
      setStatusColor('ferrari');
      setInStake(true);


    } else {
      setDayColr("primary");
      setStatusColor("primary");
      setInStake(false);

    }


    // run possible days    
    runPossibleInterestDays();

    runCalculations(z_interestDays);



  }

  const runPossibleInterestDays = ()=>{
      let z_endDay = 0;
      let thisPossibleInterestDays = 0;     
      let yeararray = [];
      let idxs = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22, 23];
      let tableOfInterest = [ 602327173169062, 626992495575658, 682465042095688, 761985998481203, 862695877467196, 982810652163463, 1121060227005890, 1276472401104000, 1448267589178370, 1635800001127570, 1838521643517700, 2055958793232460, 2287695837361680, 2533363729811420, 2792631480698150, 3065199715661290, 3350795693481670, 3649169379008750, 3960090297482470, 4283344979037570, 4618734856781090, 4966074518839090, 0 ];
      let yeardivisor = 365.25;

      
      // CALCULATE - possibleInterest Days ===================
      z_endDay = startDay + stakeDays;
    
      // REquire Checks for Scrape Day
      if(scrapeDay > startDay && scrapeDay < z_endDay){
        thisPossibleInterestDays = z_endDay - (scrapeDay);
      } else {
        // its zero or negative, but should always be gt startDay or zero
        thisPossibleInterestDays = stakeDays;
      }

      setPossibleInterestDays(thisPossibleInterestDays);
      //console.log("PID:",thisPossibleInterestDays);

      // Create The Yearly Array and calculate
      // POSSIBLE INTEREST
      
      let dayworker = thisPossibleInterestDays;   // days to use
      let totalpossibledays = 0;
      yeararray = [];
      let possiblearray = [];


      let tempPossibleInterest = 0;
      let tempTotalInterest = 0;

      // calculate total possible interest
      let yrun = idxs.map((idxs, index) => {
        
        tempPossibleInterest = 0

        //console.log(dayworker);

        if(dayworker == 0){
          
          yeararray.push(0);
          
          dayworker = 0;
          totalpossibledays = 0;

        } else if(dayworker > yeardivisor){
          // fill up this year and subtract from total
          yeararray.push(yeardivisor);
          totalpossibledays = yeardivisor; // because this is filled up
          dayworker = dayworker - yeardivisor;
          // this is a full year
          tempPossibleInterest = (tempPossibleInterest + (yeardivisor * tableOfInterest[index]) ) * principle;

        } else if (dayworker < yeardivisor && dayworker > 0) {
          
          yeararray.push(dayworker);
          totalpossibledays = dayworker;  // will be the days left
          // add the total
          tempPossibleInterest = (tempPossibleInterest + (dayworker * tableOfInterest[index])) * principle;
        

          dayworker = dayworker - yeardivisor;
          // extra check in case we went minus
          if(dayworker <= 0) {
            dayworker = 0;
          }

        } else {
          // do nothing
        }
      
        // onEveryPass
        
        tempTotalInterest = tempTotalInterest + tempPossibleInterest;
        // end of loop
        possiblearray.push({yr: idxs, td: totalpossibledays, interest: tempPossibleInterest, irate: tableOfInterest[index]  });

      })

      let pinterestamt = tempTotalInterest/1000000000000000000;

      //console.log("TOTALPI: 284: ", pinterestamt);
      setPossibleInterestAmount(pinterestamt);

      //console.log(possiblearray);
      setPossibleArray(possiblearray);
      // End possible Values
     setYearArray(yeararray);
  }

  const runCalculations = ()=>{
      let z_endDay = 0;
      let thisCurrentInterestDays = 0;     

      let idxs = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22, 23];
      let tableOfInterest = [ 602327173169062, 626992495575658, 682465042095688, 761985998481203, 862695877467196, 982810652163463, 1121060227005890, 1276472401104000, 1448267589178370, 1635800001127570, 1838521643517700, 2055958793232460, 2287695837361680, 2533363729811420, 2792631480698150, 3065199715661290, 3350795693481670, 3649169379008750, 3960090297482470, 4283344979037570, 4618734856781090, 4966074518839090, 0 ];
      let yeardivisor = 365.25;
      let z_previousScrapeDays = 0;
      
      // CALCULATE - currentInterest Days ===================
      z_endDay = startDay + stakeDays;
      

      // Stake Day Checks
      z_previousScrapeDays = scrapeDay - startDay;
      if(z_previousScrapeDays < 0){
        z_previousScrapeDays = 0;
      }
      if(z_previousScrapeDays > stakeDays){
        z_previousScrapeDays = stakeDays;
      }
      console.log(z_previousScrapeDays);

      if(scrapeDay <= 0){
        thisCurrentInterestDays = currentDay - startDay;  
      } else {
        thisCurrentInterestDays = (currentDay - startDay) - z_previousScrapeDays;
      }
      console.log("Scrape - Start", scrapeDay-startDay);
      console.log("EndDay", z_endDay);
      console.log("CurrentDay - StartDay", currentDay-startDay);

      if((scrapeDay - startDay) >= (stakeDays)){
        thisCurrentInterestDays = 0;
      }
      //setCurrentInterestDays(thisCurrentInterestDays);
      console.log("IID:",thisCurrentInterestDays);

      if(thisCurrentInterestDays <=0){
        thisCurrentInterestDays = 0;
      }
      // Need to MAX
      if(currentDay > z_endDay){
        // no more calculations
        thisCurrentInterestDays = (currentDay - startDay) - z_previousScrapeDays;
      }

      thisCurrentInterestDays = interestDays;

      // Create The Yearly Array and calculate
      // POSSIBLE INTEREST
      
      let dayworker = thisCurrentInterestDays;   // days to use
      let totalcurrentdays = 0;

      let currentarray = [];


      let tempCurrentInterest = 0;
      let tempTotalInterest = 0;

      // calculate total current interest
      let crun = idxs.map((idxs, index) => {
        
        tempCurrentInterest = 0

        //console.log(dayworker);

        if(dayworker == 0){
          

          
          dayworker = 0;
          totalcurrentdays = 0;

        } else if(dayworker > yeardivisor){
          // fill up this year and subtract from total

          totalcurrentdays = yeardivisor; // because this is filled up
          dayworker = dayworker - yeardivisor;
          // this is a full year
          tempCurrentInterest = (tempCurrentInterest + (yeardivisor * tableOfInterest[index]) ) * principle;

        } else if (dayworker < yeardivisor && dayworker > 0) {
          

          totalcurrentdays = dayworker;  // will be the days left
          // add the total
          tempCurrentInterest = (tempCurrentInterest + (dayworker * tableOfInterest[index])) * principle;
        

          dayworker = dayworker - yeardivisor;
          // extra check in case we went minus
          if(dayworker <= 0) {
            dayworker = 0;
          }

        } else {
          // do nothing
        }
      
        // onEveryPass
        
        tempTotalInterest = tempTotalInterest + tempCurrentInterest;
        // end of loop
        currentarray.push({yr: idxs, td: totalcurrentdays, interest: tempCurrentInterest, irate: tableOfInterest[index]  });

      })

      let pinterestamt = tempTotalInterest/1000000000000000000;

      //console.log("TOTALPI: 284: ", pinterestamt);
      setCurrentInterestAmount(pinterestamt);

      //console.log(currentarray);
      setFinalArray(currentarray);
      // End current Values

  }

 const scrapeInterest = ()=>{
    
    let zz_endday = startDay + stakeDays;

    if(currentDay < zz_endday && currentDay > startDay){

      // move the scraped interest into total Scraped interest
      setScrapedInterestAmount(scrapedInterestAmount + currentInterestAmount);
      
      //reset final array to blank
      let mynewfinalarray = [];
      mynewfinalarray.push({yr: 0, td: 0, interest: 0, irate: 0 });
      setFinalArray(mynewfinalarray);
      
      // recalculate possible interest days
      updateMe(currentDay);

      // reset current interest amount
      setCurrentInterestAmount(0);
      
      if(currentDay > (startDay + stakeDays)){
        setScrapeDay(startDay + stakeDays);
      } else {
        //set the scrape day to the current day
      setScrapeDay(currentDay-1);  
      }
      
    } else {

      alert("Scraping is not allowed at this time.");
    }




 }

 const startStake = async ()=> {
    
    updateMe();

    setTotalSupply(totalSupply - (principle + possibleInterestAmount));
    setStakeStarted(true);
    // still need to get the 
 }

 const endStake = ()=>{
    
    let zz_endday = startDay + stakeDays;

    if(currentDay >= zz_endday){

      setFinalInterestAmount(scrapedInterestAmount + currentInterestAmount)
      
      //reset final array to blank
      //let mynewfinalarray = [];
      //mynewfinalarray.push({yr: 0, td: 0, interest: 0, irate: 0 });
      //setFinalArray(mynewfinalarray);
      
      // recalculate possible interest days
      updateMe(currentDay);

      
      // move the scraped interest into total Scraped interest
      setScrapedInterestAmount(0);

      // reset current interest amount
      setCurrentInterestAmount(0);
      
      setPossibleInterestAmount(0);      
    //   if(currentDay > (startDay + stakeDays)){
    //     setScrapeDay(startDay + stakeDays);
    //   } else {
    //     //set the scrape day to the current day
    //   setScrapeDay(currentDay-1);  
    //   }
      setStakeStarted(false);
    } else {
      alert("End Stake penalties not yet set.");
    }




 }

    const runCalculations_OriginalWorking = (totaldays = 0) => { 


      console.log("Starting Calculations");

      let idxs = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22, 23];

      // From Solidity Contract
      let tableOfInterest = [ 602327173169062, 626992495575658, 682465042095688, 761985998481203, 862695877467196, 982810652163463, 1121060227005890, 1276472401104000, 1448267589178370, 1635800001127570, 1838521643517700, 2055958793232460, 2287695837361680, 2533363729811420, 2792631480698150, 3065199715661290, 3350795693481670, 3649169379008750, 3960090297482470, 4283344979037570, 4618734856781090, 4966074518839090, 0 ];
      
      // Decimals to be used because the above array is determined by 18 decimals
      let decimals = 1000000000000000000;

      let final = []; // used for console log testing

      let yeardivisor = 365.25;
  
      let totaldays2 = 0;
      let myinterest = 0;

      let totalinterest = 0;

      let yeararray = [];


     // get total days to calculate



      // calculate ACTUAL Amount of interest for Right now
      // based on currentDay and Scraped Days Already



      // REcurse through 23 year designations
      let txt2 = idxs.map((idxs, index) => {
 
              {/*<div key={`${idxs}`}>{idxs}</div>*/}

              if(totaldays <= 0) {
                totaldays = 0;
                totaldays2 = 0;
              
              } else if(totaldays < yeardivisor && totaldays > 0){
                totaldays2 = totaldays;
                // calculate interest on these days
                myinterest = ((totaldays2 * tableOfInterest[index]) * principle) / decimals;

              } else if(totaldays > yeardivisor ) {
                  totaldays = totaldays - yeardivisor;
              } else {
                totaldays = totaldays - yeardivisor;
                totaldays2 = yeardivisor;
              }

              

              if(totaldays <= 0) {
                totaldays = 0;
              }

              
              
              // add to the total Interest
              totalinterest = totalinterest + myinterest;
              


              final.push({yr: idxs, td: totaldays2, interest: myinterest, irate: tableOfInterest[index]  });

        });

      console.log(final);
      
      // set the final array
      setFinalArray(final);

      //console.log(totalinterest);
      //setPossibleInterestAmount(totalinterest);

    }

    const _setCurrentDay = (thisval)=>{
      thisval = parseInt(thisval);
      if(isNaN(thisval)){
        thisval = 0;
      }
      setCurrentDay(thisval);
      return 
    }
    const _setPrinciple = (thisval)  =>{
      thisval = parseInt(thisval);

      if(isNaN(thisval)){
        thisval = 0;
      }
      setPrinciple(thisval);
      return 
    }
    const _setStartDay = (thisval)  =>{
      thisval = parseInt(thisval);
      // reset error
      settfstartdayerror("");

      if(isNaN(thisval)){
        thisval = 0;
      }
    
      // if(thisval > currentDay){
      //   settfstartdayerror("error");
      //   thisval = currentDay;
      // }
      
      setStartDay(thisval);
      return 
    }
    const _setStakeDays = (thisval)  =>{
      thisval = parseInt(thisval);

      if(isNaN(thisval)){
        thisval = 0;
      }
      setStakeDays(thisval); 
      return 
    }
    const _setScrapeDay = (thisval)  =>{
      thisval = parseInt(thisval);

      if(isNaN(thisval)){
        thisval = 0;
      }
      setScrapeDay(thisval); 
      return 
    }
    const _setMaxViewDays = (thisval)=>{
      thisval = parseInt(thisval);
      if(isNaN(thisval)){
        thisval = 0;
      }
      setViewMaxDays(thisval);
      return 
    }

    const _setDayUpDown = (thisval)=>{
      thisval = parseInt(thisval);
      if(isNaN(thisval)){
        thisval = 0;
      }
      setDayUpDown(thisval);
      return 
    }

  const handleDayPCTCheckbox = (event) => {
    setDayPCTCheck(event.target.checked);
    console.log(event.target.checked);

    let mypct = parseInt(viewMaxDays/100);

    if(event.target.checked == true){
      //set to pct
      
      if(mypct < 1){
        mypct = 1;
      }
      setDayUpDown(mypct);
    } else {
      // setvalue to the default of 1
      mypct = 1;
      setDayUpDown(mypct); 
    }

  };



  return (

      <Container>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                
                <TextField id="tf_currendDay" label="Current Day" variant="filled" size="small" value={currentDay} onChange={ (e)=>{ _setCurrentDay(e.target.value) } } />
                <TextField id="tf_principle" label="Principle" variant="filled"  size="small"   value={principle} onChange={ (e)=>{  _setPrinciple(e.target.value)   } } />
                <TextField id="tf_stakeDays" label="Start Day" variant="filled" size="small"  value={startDay} onChange={ (e)=>{    _setStartDay(e.target.value)   } } />
                <TextField id="tf_stakeDays" label="Stake Days" variant="filled" size="small"    value={stakeDays} onChange={ (e)=>{  _setStakeDays(e.target.value)   } } />
                <TextField id="tf_stakeDays" label="Scrape Day" variant="filled" size="small"   value={scrapeDay} onChange={ (e)=>{  _setScrapeDay(e.target.value)   } } />
<br /><br />
<ButtonGroup variant="outlined" aria-label="outlined primary button group">
                <Button onClick={ (e)=>{startStake()} } disabled={stakeStarted}>Start</Button>
                  <Button onClick={(e)=>{scrapeInterest()} } disabled={!inStake}>Scrape</Button>
                  <Button onClick={(e)=>{endStake()} } disabled={inStake}>End Stake</Button>
                </ButtonGroup>
<br /><br />

                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Button onClick={()=>{ let newday = currentDay + dayUpDown; setCurrentDay(newday) ; updateMe(newday)}}><AddIcon /></Button>
                    <Button onClick={()=>{ let newday = currentDay - dayUpDown; setCurrentDay(newday) ; updateMe(newday)}}><RemoveIcon /></Button>
                    <Tooltip title="When checked, sets day up/down value to percentage of View Resolution">
                    <Checkbox
                    checked={dayPCTCheck}
                    onChange={handleDayPCTCheckbox}
                    inputProps={{ 'aria-label': 'controlled' }}
                    />
                    </Tooltip>
                </ButtonGroup>
            
            </Grid>
            </Grid>

          </Grid>
          <Grid item xs={8}>
            <Box className="ptxtBo">
                <div className="ptxt"><div className="ptxtlabel">TotalSupply:</div> {totalSupply} </div>
                <div className="ptxt"><div className="ptxtlabel">CurrentDay:</div> {currentDay} </div>
                <div className="ptxt"><div className="ptxtlabel">StartDay:</div> {startDay} </div>
                <div className="ptxt"><div className="ptxtlabel">EndDay:</div> {endDay} </div>
                <div className="ptxt"><div className="ptxtlabel">ScrapeDay:</div> {scrapeDay} </div>
                <div className="ptxt"><div className="ptxtlabel">InterestDays:</div> {interestDays} </div>
                <div className="ptxt"><div className="ptxtlabel">PossibleInterestDays:</div> {possibleInterestDays} </div>
                                

                <div className="ptxt"><div className="ptxtlabel">PrevScrapeDays:</div> {previousScrapeDays} </div>
                
                   

                <div className="ptxt"><div className="ptxtlabel">StakeDays:</div> {stakeDays} </div>
                <div className="ptxt"><div className="ptxtlabel">Poss Int Amt:</div> {possibleInterestAmount} </div>
                
                <div className="ptxt"><div className="ptxtlabel">Current Interest Amount:</div> {currentInterestAmount} </div>
                <div className="ptxt"><div className="ptxtlabel">Total Scraped Interest:</div> {scrapedInterestAmount} </div>
                <div className="ptxt"><div className="ptxtlabel">Final Interest:</div> {finalInterestAmount} </div>
                
            </Box>
          </Grid>

          <Grid item xs={12} className={dayColor}>
            {/*<Daysliders 
                myval={[12,3,37,viewMaxDays]} 
                interestDays={[0,15, viewMaxDays]} 
                viewmaxdays={viewMaxDays} />  
            */}

            <StatusBox clr= {statusColor} totaldays={viewMaxDays}/>


          </Grid>


{/* -------------------------------------------*/}
          <Grid item xs={4}>
            {/*{renderStakelist(stakelist)}*/}  
            View Resolution
          </Grid>

          <Grid item xs={8}>
             <Slider        
                onChange={(e)=>{setViewMaxDays(e.target.value)}}
                value={viewMaxDays}
                defaultValue={viewMaxDays}
                color="primary"
                valueLabelDisplay="on"
                max={10000}
                min={100}
               />
          </Grid>
{/* -------------------------------------------*/}

{/* -------------------------------------------*/}
          <Grid item xs={4}>
            Current Day
          </Grid>

          <Grid item xs={8}>
             <Slider        
                onChange={(e)=>{currentDayChange(e.target.value)}}
                value={currentDay}
                defaultValue={currentDay}
                color={dayColor}
                valueLabelDisplay="on"
                max={viewMaxDays}
                min={0}
               />
          </Grid>
{/* -------------------------------------------*/}


        </Grid>






{/*DETAILS*/}
<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Final Array</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <PossibleArrayViewer possiblearray={finalarray} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>PossibleArray</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PossibleArrayViewer possiblearray={possiblearray} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          >
          Other Items
          
        </AccordionSummary>
        <AccordionDetails>
            <TextField id="tf_maxviewdays" label="View Resolution - Max View Days" variant="filled" size="small" value={viewMaxDays} onChange={ (e)=>{ _setMaxViewDays(e.target.value) } } />
            <TextField id="tf_dayup" label="Day Up Down Value" variant="filled" size="small" value={dayUpDown} onChange={ (e)=>{_setDayUpDown(e.target.value) } } />
        </AccordionDetails>
      </Accordion>




      </Container>

    );
};

export default StakingCalculator;
import ThemeTest from '../components/ThemeTest';
import Box from '@mui/material/Box';


const Home = () => {

  return (
    <Box>
      <h1>Home</h1>
      <h5>
        This is created as a simple tool to model some key concepts.
        <br/><br/>
        It has a few bugs, but should be "best guess, spock" ready.
        I need to devote my time to getting the contract ready, and not this tool, 
        but doing this has helped me know better where to focus time on the contract.
        <br/><br/>Those of you React guys, may know that getting the next 20% of this project completed will take 80% more of mytime.
        <br/><br/>
        Start button is not necessary, but if you want to "scrape" test, 
        you'll need to start a stake.

        <br/> To make things faster, the checkbox will calcluate percentage of the entire "View Resolution" / 100 and the plus/minus buttons will work accordingly.  Unclicking it will reset the day up/down value to 1.
        
        <br/>        
        <br/><br/>
        Enjoy
      </h5>

    </Box>
    );
  };

export default Home;